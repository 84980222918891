.title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
}

.about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 40px 50px;
}

.about-column {
    max-width: 20vw;
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    padding: 0px 40px;
    font-size: 23px;
}

.about-column a {
    display: block;
    /* Makes each anchor and paragraph element occupy its own line */
    margin-bottom: 0px;
    margin-top: 0px;
    color: #FFFFFF;
}

.about-column p {
    font-size: 12px;
    display: block;
    /* Makes each anchor and paragraph element occupy its own line */
    margin-bottom: 20px;
    /* Adds space below each anchor and paragraph */
    margin-top: 0px;
    color: #FFFFFF;

}


.about-img {
    flex: 0 1 auto;
    width: 200px;
}

.about-img img {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 5px;
}

.skills-section {
    /* display: flex; */
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px 40px;
}

footer {
    margin-top: auto;
    padding: 20px 0px;
    color: #7f7a7a;
    width: 100%;
    text-align: center;
}

@media (max-width: 1000px) {
    .about-section {
        flex-direction: column;
    }

    .about-column {
        max-width: 90vw;
        flex-direction: row;
    }

    .about-column a {
        padding: 0px 15px 10px;
    }
    .about-column p {
        display: none;
    }
}

body {
    color: white;
}

/* .project-section {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0px 100px;
} */

.researchskills-section {
    display: flex;
    /* Use flexbox layout */
    justify-content: space-between;
    /* Space out the child elements */
    align-items: center;
    /* Align items vertically */
    padding: 20px 80px;
    /* Add some padding around the container */
    gap: 20px;
}

.researchskills-column {
    flex: 1;
    /* Each box will take up equal amount of space within the container */
    padding: 20px;
    /* Adds padding inside each box */
    background-color: #3c3c3c;
    /* Background color for visual distinction */
    text-align: center;
    border-radius: 10px;
    height: 180px;
    width: 40vw;
    /* Centers the text inside each box */
}

.popup-box {
    display: flex;
    align-items: center;
    justify-content: center;
}