/* .App {
  text-align: center;
  background-color: #141414;
} */

/* 
.App-header {
  background-color: #141414;
  padding: 80px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.nav-bar {
  background-color: #141414;
  padding: 80px 0px 20px 0px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

body {
  background-color: #141414;
  font-family: 'Roboto' sans-serif;
  margin: 0;
  padding: 0;
}

