.project-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.project-slider {
  max-width: 1000px;
  justify-content: center;
}

.slide-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.slide-content {
    display: flex;
    flex-direction: column;
    /* Stack children vertically */
    align-items: center;
    /* Center children horizontally */
    justify-content: center;
    /* Center children vertically if there's enough space */
    text-align: center;
    /* Center text for the caption */
    height: 100%;
    /* Take up full height to allow for vertical centering */
}

.slide-content .slide-img {
  height: 50vw;
  max-height: 500px;
  padding: 0px;
  margin: 0px;
}

.slide-content h3 {
  margin-top: 10px;
  margin-bottom: 10px; /* Adjust as needed */
}

.slide-content p {
  margin-top: 0; /* Adjust as needed */
}

/* Target all slides and apply a fade effect */
/* .slick-slide {
    opacity: 0.5; 
    transition: opacity 0.5s ease; 
} */
  
  /* Ensure the centered slide is fully opaque */
  .slick-center {
    opacity: 1 !important; /* Override to make the active slide fully visible */
  }

  .slick-arrow.slick-next:before {
    content: "";
  }
  
  .slick-arrow.slick-prev:before {
    content: "";
  }

  @media (max-width: 1100px) {
    .project-slider {
      max-width: 80vw;
    }
}